//=================================================
// Navigation
//=================================================

// Add dropdown option to link rails if not all options can fit
import { getReducedMotion } from './user-preferences';

function adjustLinkRail(rail) {
    const wrapper = rail.parentElement;
    const tolerance = 8; // Add a little "give"
    const scrollable = rail.scrollWidth > (rail.offsetWidth + tolerance);
    const fullyScrolled = (rail.scrollLeft + rail.offsetWidth + tolerance) >= rail.scrollWidth;

    // 1. All the way to the right
    // 2. Partially to the right
    // 3. No scroll, fully to the left
    // 4. Not scrollable, fits in viewport
    if (scrollable && fullyScrolled) {
        wrapper.classList.add('left');
        wrapper.classList.remove('right');
    } else if (scrollable && rail.scrollLeft > tolerance) {
        wrapper.classList.add('left', 'right');
    } else if (scrollable) {
        wrapper.classList.remove('left');
        wrapper.classList.add('right');
    } else {
        wrapper.classList.remove('left', 'right');
    }
}

// Set up or update link rails (which could be added dynamically)
export function updateLinkRails() {
    // Toggle click handling
    document.querySelectorAll('.link-rail-wrapper').forEach((wrapper) => {
        if (wrapper.dataset.initialized !== 'true') {
            // wrapper.dataset.initialized = 'true'; // Only initialize once...
            const rail = wrapper.querySelector('.link-rail');

            rail.addEventListener('scroll', () => {
                adjustLinkRail(rail);
            });

            // Click to scroll left
            wrapper.querySelectorAll('.link-rail__left').forEach((el) => {
                el.addEventListener('click', (e) => {
                    e.preventDefault();
                    rail.scrollTo({ left: rail.scrollLeft - (rail.offsetWidth * 33.33), behavior: 'smooth' });
                });
            });

            // Click to scroll right
            wrapper.querySelectorAll('.link-rail__right').forEach((el) => {
                el.addEventListener('click', (e) => {
                    e.preventDefault();
                    rail.scrollTo({ left: rail.scrollLeft + (rail.offsetWidth * 33.33), behavior: 'smooth' });
                });
            });
        }
    });

    // Handle any adjustments to what's spilling over/fading
    document.querySelectorAll('.link-rail').forEach((el) => {
        adjustLinkRail(el);
    });
}

function trackNav(selector, label) {
    if (useAnalytics) {
        document.querySelectorAll(selector).forEach((link) => {
            // Fallback to text if a value for tracking isn't explicitly set
            let { value } = link.dataset;
            if (!value) {
                value = link.innerText;
            }
            value = value.toLowerCase().trim();

            link.addEventListener('click', () => {
                dataLayer.push({
                    event: 'navigation',
                    label,
                    value,
                });
            });
        });
    }
}

// Close all secondary navs
export function navigationSecondaryClose() {
    // Close all open secondary navs
    document.querySelectorAll('.primary-nav__item').forEach((el) => {
        el.classList.remove('open');
    });

    // Reset all aria-expanded elements for primary toggles
    document.querySelectorAll('.primary-nav__link--toggle').forEach((el) => {
        el.setAttribute('aria-expanded', 'false');
    });

    // Add inactive class back if no primary nav links are active (i.e. on the homepage)
    // if (!document.querySelector('.primary-nav__item.active')) {
    //     document.querySelector('.primary-nav')?.classList.add('primary-nav--inactive');
    // }
}

// Reset all open elements of the navs
export function navigationClose() {
    navigationSecondaryClose();

    // Reset all aria-expanded elements for the menu
    document.querySelectorAll('.mini-header__menu-small').forEach((el) => {
        el.setAttribute('aria-expanded', 'false');
    });

    // Reset overlay bits only if the nav overlay is actually open
    if (document.querySelector('.primary-nav.open')) {
        document.body.classList.remove('overlay-open');
    }

    // Remove open status from nav
    document.querySelector('.primary-nav')?.classList.remove('open');
}

function handleNavigationClicks(e) {
    if (!e.target.closest('.navigation')) {
        return;
    }

    const primaryToggle = e.target.closest('.primary-nav__link--toggle');
    const secondaryLink = e.target.closest('.secondary-nav__link');
    const searchClear = e.target.closest('.input__clear--search');
    const searchSubmit = e.target.closest('.search .input__submit');
    const miniMenu = e.target.closest('.mini-header__menu');
    const menuSmall = e.target.closest('.mini-header__menu-small');
    const menuSmallClose = e.target.closest('.primary-nav-small-header__close');
    const menuSkip = e.target.closest('.skip-to-content');

    const primary = document.querySelector('.primary-nav');
    const primaryItems = primary?.querySelectorAll('.primary-nav__item');

    // Note: only close navigation on page load for better feeling UX
    if (primaryToggle) {
        // Handle primary navigation clicks
        const item = primaryToggle.closest('.primary-nav__item');
        const alreadyOpen = item.classList.contains('open');

        navigationSecondaryClose();

        // Open new secondary nav if existing open nav wasn't selected
        if (!alreadyOpen) {
            item.classList.add('open');
            // primary.classList.remove('primary-nav--inactive'); // This is necessary for homepage nav item hover behavior
            primaryToggle.setAttribute('aria-expanded', 'true');

            // Focus on input if search
            if (item.classList.contains('primary-nav__item--search')) {
                item.querySelector('input')?.focus();
            }
        }

    } else if (secondaryLink) {
        // Handle secondary link clicks
        primaryItems.forEach((otherItem) => {
            otherItem.classList.remove('active');
        });
        const item = e.target.closest('.primary-nav__item');
        item.classList.add('active');

    } else if (searchClear) {
        // Handle clicks on search input clears (which only clear that input)
        const input = searchClear.parentElement.querySelector('input');
        if (input) {
            input.value = '';
        }
        const searchNavButton = document.querySelector('.primary-nav__item--search.open');
        if (searchNavButton) {
            searchNavButton.classList.remove('open');
        }

        // On large screens only close the navigation on search clear (on small just clear the input)
        if (!(window.isNavSmall && searchClear.classList.contains('input__clear--nav'))) {
            navigationClose();
        }

    } else if (searchSubmit) {
        // Handle search submission
        e.preventDefault();

        const input = searchSubmit.parentElement.querySelector('input');
        if (input.value) {
            Turbo.visit(`/search?q%5Bsearch_cont%5D=${ input.value }`);
        } else {
            Turbo.visit('/search');
        }

    } else if (menuSmall) {
        // Handle small menu opening
        e.preventDefault();
        e.stopPropagation();

        document.body.classList.add('overlay-open');
        primary.classList.add('open');
        menuSmall.setAttribute('aria-expanded', 'true');
        primaryItems[0].focus();

    } else if (menuSmallClose) {
        navigationClose();

    } else if (miniMenu) {
        // Scroll to top if "menu" is clicked on large screens
        const behavior = getReducedMotion() ? 'instant' : 'smooth';
        window.scrollTo({ top: 0, behavior: behavior });

    } else if (menuSkip) {
        // Accessible "skip to main content" link
        // Adapts learnings from https://axesslab.com/skip-links/
        const main = document.getElementById('main');
        if (main) {
            // Make main temporarily tabbable
            main.setAttribute('tabindex', '-1');
            main.addEventListener('blur', () => {
                main.setAttribute('tabindex', null);
            }, { once: true });
            main.focus();
        }
    }
}

function handleNavigationKeydowns(e) {
    if (!e.target.closest('.navigation')) {
        return;
    }

    let firstTab = e.target.closest('.primary-nav-small-header__title');
    let lastTab = e.target.closest('.nav-actions--small .nav-actions__link:last-child');

    if (firstTab) {
        // Handle trapping focus from tabbing backwards from first element
        lastTab = document.querySelector('.nav-actions--small .nav-actions__link:last-child');
        // Check if this is the tab + shift key
        if (e.key === 'Tab' && e.shiftKey) {
            e.preventDefault();
            if (lastTab) {
                lastTab.focus();
            }
        }

    } else if (lastTab) {
        // Handle trapping focus from tabbing forwards from last element
        firstTab = document.querySelector('.primary-nav-small-header__title');
        // Check if this is the tab key and NOT also shift
        if (e.key === 'Tab' && !e.shiftKey) {
            e.preventDefault();
            if (firstTab) {
                firstTab.focus();
            }
        }
    }
}

export function setupScrollableMenu() {
    const miniMenu = document.querySelector('.mini-header__menu');
    const primary = document.querySelector('.primary-nav');
    // Set scrollable menu behavior
    const callback = (entries) => {
        entries.forEach((entry) => {
            // When the primary nav scrolls out of view we need to do some things...
            // Check for scrollY to handle an momentary issue when Turbo replaces the page
            if ((entry.intersectionRatio === 0) && (window.scrollY > 0) && !document.querySelector('body.shuffle')) {
                // If the menu button is not at all active, activate it
                if (!miniMenu.classList.contains('active') || !miniMenu.classList.contains('will-be-active')) {
                    miniMenu.classList.add('will-be-active');

                    setTimeout(() => {
                        miniMenu.classList.add('active');
                    }, getReducedMotion() ? 0 : window.transitionDurationFast);
                }

            } else if (entry.intersectionRatio === 1) {
                // If the menu button is at all active, inactivate it
                if (miniMenu.classList.contains('active') || miniMenu.classList.contains('will-be-active')) {
                    miniMenu.classList.remove('active');

                    setTimeout(() => {
                        miniMenu.classList.remove('will-be-active');
                    }, getReducedMotion() ? 0 : window.transitionDurationFast);
                }

                // Drop any fragments from the url from jumplinks
                if (window.location.hash && document.readyState === 'complete') {
                    window.history.replaceState(window.history.state, document.title, `${ window.location.pathname }${ window.location.search }`);
                }
            }
        });
    };
    const observer = new IntersectionObserver(callback, { threshold: [0, 1.0] });
    observer.observe(primary);
}

export function setupAnimatedButtons() {
    const animateButton = (animatedButton) => {
        if (animatedButton.dataset.initialized === 'true') {
            return;
        }

        animatedButton.dataset.initialized = 'true';

        animatedButton.addEventListener('animationstart', () => {
            animatedButton.setAttribute('data-animation', '1');
        });

        animatedButton.addEventListener('animationend', () => {
            animatedButton.setAttribute('data-animation', '0');
        });

        if (!getReducedMotion()) {
            animatedButton.classList.add('animate');
        }

        animatedButton.addEventListener('mouseenter', () => {
            if (getReducedMotion()) {
                return;
            }

            // Check if the animation is still running
            if (animatedButton.dataset.animation === '1') {
                // Add a one time listener for when it's done, then re-animate
                animatedButton.addEventListener('animationend', () => {
                    animatedButton.classList.remove('animate');

                    setTimeout(() => {
                        animatedButton.classList.add('animate');
                    }, 1);
                }, { once: true });

            } else {
                // Re-animate immediately
                animatedButton.classList.remove('animate');

                setTimeout(() => {
                    animatedButton.classList.add('animate');
                }, 10);
            }
        });
    };

    // Ailey button custom behavior
    // Only works with two override texts
    const animateAileyButton = (animatedButton) => {
        if (animatedButton.dataset.initialized === 'true') {
            return;
        }

        animatedButton.dataset.initialized = 'true';

        if (!getReducedMotion()) {
            animatedButton.classList.add('animate');
        }

        animatedButton.addEventListener('mouseenter', () => {
            if (!getReducedMotion()) {
                animatedButton.classList.remove('animate');

                setTimeout(() => {
                    animatedButton.classList.add('animate');
                }, 10);
            }
        });

        animatedButton.addEventListener('mouseleave', () => {
            animatedButton.classList.remove('animate');
        });
    };

    // Button text animation
    document.querySelectorAll('.animated-btn').forEach((animatedButton) => {
        if (animatedButton.matches('.btn--ailey')) {
            animateAileyButton(animatedButton)
        } else {
            animateButton(animatedButton);
        }
    });

}

function activateNavLink(el) {
    const item = el.closest('.secondary-nav__item');
    const primaryNavItem = item.closest('.primary-nav__item');
    item.classList.add('active');
    primaryNavItem.classList.add('active');
}

function deactivateNavLink(el) {
    const item = el.closest('.secondary-nav__item');
    item.classList.remove('active');
}

// Update active states in navigation based on url
export function updateNavigation() {
    const path = window.location.pathname.toLowerCase();
    const primarySegment = path.split('/')[1];
    const primaryNav = document.querySelector('.primary-nav');
    let isActive = false;

    // if (primarySegment) {
    const primaryNavItems = primaryNav?.querySelectorAll('.primary-nav__item');
    primaryNavItems.forEach((el) => el.classList.remove('active'));

    // Don't bother checking on root
    if (path !== '/') {
        // Check for exact matches
        const secondaryNavLinks = document.querySelectorAll('.secondary-nav__link');
        secondaryNavLinks.forEach((el) => {
            if (el.getAttribute('href') === path) {
                isActive = true;
                activateNavLink(el);
            } else {
                deactivateNavLink(el);
            }
        });

        // If nothing is active, go through and check by startsWith instead (but just for highlighting the top level section)
        if (!isActive) {
            secondaryNavLinks.forEach((el) => {
                // Stop checking after the first match
                if (isActive) {
                    return
                }

                const primaryNavItem = el.closest('.primary-nav__item');
                if (el.getAttribute('href').startsWith(`/${ primarySegment }`)) {
                    isActive = true;
                    primaryNavItem.classList.add('active');
                }
            });
        }
    }

    if (isActive) {
        primaryNav.classList.add('active');
    } else {
        primaryNav.classList.remove('active');
    }
}

// Visually we change treatment on hovering if there are no active items
function handleNavigationMouseOver(e) {
    const primaryNav = e.target.closest('.primary-nav');
    primaryNav?.classList.add('hovering');
}

function handleNavigationMouseOut(e) {
    const primaryNav = e.target.closest('.primary-nav');
    primaryNav?.classList.remove('hovering');
}

export function setupNavigation() {
    const navigation = document.querySelector('.navigation');

    if (navigation) {
        updateNavigation();

        if (navigation.dataset.initialized !== 'true') {
            navigation.dataset.initialized = 'true';

            // Clicks
            navigation.removeEventListener('click', handleNavigationClicks);
            navigation.addEventListener('click', handleNavigationClicks);

            // Keydowns
            navigation.removeEventListener('keydown', handleNavigationKeydowns);
            navigation.addEventListener('keydown', handleNavigationKeydowns);

            // Hovers
            const primaryNavList = navigation.querySelector('.primary-nav__list');
            primaryNavList.removeEventListener('mouseover', handleNavigationMouseOver);
            primaryNavList.addEventListener('mouseover', handleNavigationMouseOver);
            primaryNavList.removeEventListener('mouseleave', handleNavigationMouseOut);
            primaryNavList.addEventListener('mouseleave', handleNavigationMouseOut);

            // Analytics
            trackNav('.primary-nav__link', 'primary');
            trackNav('.secondary-nav__link', 'secondary');
            trackNav('.link-rail-wrapper:not(.link-rail-wrapper--jumplinks) .link-rail__link', 'tertiary');
            trackNav('.link-rail-wrapper--jumplinks .link-rail__link', 'jumplinks');
            trackNav('.footer a', 'footer');
            trackNav('.breadcrumbs__link', 'breadcrumbs');
            trackNav('.mini-header__menu, .mini-header__menu-small', 'menu');
            trackNav('.mini-header__hours, .primary-nav__hours', 'hours');
            trackNav('.nav-actions__link', 'actions');

            // Run on initial load
            updateLinkRails();
            setupAnimatedButtons();
            setupScrollableMenu();
        }
    }
}
